import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import CountdownTimer from './CountDownTimer';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16, // Border radius
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '30%', // Width
          borderRadius: '5px', // Border radius
          backgroundImage: 'linear-gradient(to right, #DC86D8, #F8CC31)', // Gradient background
        },
        bar: {
          backgroundColor: '#333445', // Bar color
        },
      },
    },
  },
});
const Order = ({  avaliableAmount,setOrder,tokenSymbol }) => {

  const [value, setValue] = useState(0);
  const handleIncrement = () => {
    if (value < 9) {
      setValue((prevValue) => prevValue + 1);
    }
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue((prevValue) => prevValue - 1);
    }
  };
  const handleInput = (event) => {
    // Remove non-digit characters
    event.target.value = event.target.value.replace(/\D/g, '');

    // Ensure the value is an integer
    event.target.value = parseInt(event.target.value, 10) || '';

    // Limit the maximum value to 9
    if (event.target.value > 9) {
      event.target.value = 9;
    }
  };
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} container justifyContent="center">
        <div style={{ color: '#FFFFFF', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
          Available:{' '}
        </div>
        <div style={{ borderRadius: '5px', border: 'solid 2px #FFFFFF' }}>
          <span style={{ color: '#F8CC31', fontWeight: '700', display: 'inline-block', padding: '2px 6px' }}>
            {avaliableAmount} {tokenSymbol}
          </span>
        </div>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <div >
          <div style={{ padding: '3px 10px', border: 'solid 1px #8E8D9D', borderRadius: '30px', backgroundColor: '#1B192C' }}>
            <div style={{ border: 'solid 1px #8E8D9D', borderRadius: '50px', display: 'inline-block' }}>
              <IconButton size="small" style={{ color: '#8E8D9D' }} onClick={handleDecrement}>
                <RemoveIcon />
              </IconButton>
            </div>
            <Input
              type="text" // Change the type to text to allow non-numeric characters temporarily
              placeholder="Amount MTN"
              style={{
                color: '#FFFFFF',
                padding: '0px 30px',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                textAlign: 'center', // Center-align the input text
              }}
              inputProps={{
                pattern: '^[0-9]*$', // Ensure the value is numeric
                max: 9,
              }}
              value={value}
              onInput={handleInput}
            />
            <div style={{ border: 'solid 1px #8E8D9D', borderRadius: '50px', display: 'inline-block' }}>
              <IconButton size="small" style={{ color: '#8E8D9D' }} onClick={handleIncrement}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
          </div>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <ThemeProvider theme={theme}>
          <Button startIcon={<ExpandMoreIcon />}       onClick={() => setOrder(value, 1)}
           variant="contained" style={{ backgroundColor: '#F74E81', color: '#000000', width: '250px', marginTop:'5px' }}>
            <Typography style={{ textTransform: 'none' }}>
              Guess <span style={{ fontWeight: '700' }}> DOWN </span>
            </Typography>
          </Button>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <Button startIcon={<KeyboardArrowUpIcon />}        onClick={() => setOrder(value, 0)}
          variant="contained" style={{ backgroundColor: '#55F2A9', color: '#000000', width: '250px', marginTop:'5px' }}>
            <Typography style={{ textTransform: 'none' }}>
              Guess <span style={{ fontWeight: '700' }}> UP </span>
            </Typography>
          </Button>
        </ThemeProvider>
      </Grid>
      <CountdownTimer theme={theme}/>
     

      
    </Grid>
  );
};

export default Order;
