import {
    createWeb3Modal,
    defaultConfig,
    useWeb3Modal,
    useWeb3ModalAccount
  } from '@web3modal/ethers5/react';
  
  const projectId = '183fc7656623c87b1dc10dc993002bb0';
  if (!projectId) {
    throw new Error('VITE_PROJECT_ID is not set');
  }
  const rpcUrl_Network = 'https://api.avax-test.network/ext/bc/C/rpc';
  const chainId_Network = 43113;
  const currency_Network  = 'AVAX';
  const explorerUrl_Network = 'https://testnet.explorer.avax.network/';
  const chainName_Network = 'Avalanche FUJI Testnet'
  const chains = [
    {
      chainId: chainId_Network,
      name: chainName_Network,
      currency: currency_Network,
      explorerUrl: explorerUrl_Network,
      rpcUrl: rpcUrl_Network,
    },
  ];
  
  const ethersConfig = defaultConfig({
    metadata: {
      name: 'Web3Modal',
      description: 'Web3Modal Laboratory',
      url: 'https://web3modal.com',
      icons: ['https://avatars.githubusercontent.com/u/37784886'],
    },
    defaultChainId: chainId_Network,
    rpcUrl: rpcUrl_Network,
  });
  
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true,
    themeMode: 'light',
    themeVariables: {
      '--w3m-color-mix': '#00DCFF',
      '--w3m-color-mix-strength': 20,
    },
    onConnect: () => {
        // Refresh the page on connect
        console.log("onConnect")
        window.location.reload();
    },
    onDisconnect: () => {
        // Refresh the page on disconnect
        console.log("onDisconnect")
        window.location.reload();
    },
  });
  
  export default function ConnectButtonComponent() {
    const modal = useWeb3Modal();
    const { address,  isConnected,chainId } = useWeb3ModalAccount()
    
    const handleConnectButtonClick = () => {
      consoleLogVariables();
      modal.open();
    };
  
    const handleChooseNetworkButtonClick = () => {
        consoleLogVariables();
      modal.open({ view: 'Networks' });
    };
    const consoleLogVariables  = () =>{
        console.log('isConnected',isConnected)
        console.log('chainId',chainId);
        console.log('address',address)
    }
    const shortenHexAddress = (hexAddress) =>{
        if (hexAddress.length < 10) {
            // Check if the input is too short to be a valid hex address
            return hexAddress;
        }

        const prefix = hexAddress.slice(0, 5); // Take the first 6 characters
        const suffix = hexAddress.slice(-2); // Take the last 4 characters

        return `${prefix}...${suffix}`;
    }
    return (
      <>
        {isConnected ? 
           chainId === chainId_Network ? 
           <button onClick={handleConnectButtonClick}>{shortenHexAddress(address)}</button>
          :
          <button onClick={handleChooseNetworkButtonClick}>Change Network</button>
          :   
          <button onClick={handleConnectButtonClick}>Connect Wallet</button>
        }
      </>
    );
  }
  