import React from 'react';
import notSelected from '../images/graph.svg';
import selected from '../images/group43.svg';

const GraphButton = ({ onClick , isClicked}) => {

  return (
      <div
        style={{
          backgroundColor: '#1B192C',
          height: '50px',
          width: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px',
          border: 'solid 2px #F8CC31'
        }}
        onClick={onClick}
      >
        <img
          style={{ zIndex: '3', right: '5px', top: '5px' }}
          src={isClicked ? selected : notSelected}
          alt="icon"
        />
      </div>
  );
};

export default GraphButton;
