import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from '@mui/material/styles';

function CountdownTimer({ theme }) {

    function calculateSeconds(){
        const seconds = Math.floor(new Date().getTime() / 1000); // Get current time in seconds without milliseconds
        let secondRemaining = seconds % 900; // Remaining seconds in the 15-minute interval
        secondRemaining = 900 - secondRemaining;
        return secondRemaining;
    }

    function secondsToMinutesAndSeconds(totalSeconds) {
        // Calculate minutes
        let minutes = Math.floor(totalSeconds / 60);
        // Calculate remaining seconds
        let seconds = totalSeconds % 60;
    
        return {
            minutes: minutes,
            seconds: seconds
        };
    }

    const [timeLeft,setTimeLeft] = useState(secondsToMinutesAndSeconds(calculateSeconds()));

    
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(secondsToMinutesAndSeconds(calculateSeconds()));
        }, 1000);

        return () => clearTimeout(timer);
    });
    
    const { minutes, seconds } = timeLeft;
    const totalDurationInSeconds = 15 * 60; // Total duration is 15 minutes
    const remainingTimeInSeconds = minutes * 60 + seconds; // Calculate remaining time in seconds

    // Calculate progress value
    const progressValue = Math.round(((totalDurationInSeconds - remainingTimeInSeconds) / totalDurationInSeconds) * 100);

    const formattedTime = String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');

    return (
        <>
            <Grid item xs={12} container justifyContent="center" style={{ fontWeight: '700' }}>
                <span style={{ color: '#FFFFFF' }}>Count Down</span> &nbsp; &nbsp; <span style={{ color: '#F8CC31' }}>{formattedTime}</span>
            </Grid>
            <Grid item xs={12} container justifyContent="center" style={{ paddingBottom: '30px' }}>
                <ThemeProvider theme={theme}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </ThemeProvider>
            </Grid>
        </>
    );
}

export default CountdownTimer;
