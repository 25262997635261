import React from 'react';
import graphIcon from '../images/graph_header.svg';
import Button from '@mui/material/Button';


const GraphHeader = () => {
  return (
    <div style={{
        height: '56pt',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Button style={{ width: '100%', height: '100%', fontSize:'20px',fontWeight:'700', textTransform: 'none', color: '#F8CC31' }} >
          <img src={graphIcon} style={{ paddingRight: '12px' }} alt="" />
          Graph
        </Button>
      </div>
  );
};

export default GraphHeader;