import { ethers } from 'ethers';
import TOKEN_ABI from "../contracts/TokenContract.json";
import ContractABI from '../contracts/CoinPriceGuessing.json';

// Replace the placeholders with your actual values
const contractAddress = "0xf3Aa71686a3C526a3c932fe011077073e1d890E8";
const tokenAddress = "0x38a13bABcfa6bF3cBF0646896f3D4779c762737B";
const nodeUrl = 'https://avalanche-fuji.infura.io/v3/eb59bfa8b06e48909c8ae76e4f109e64';
const chainId_Network = 43113;

class UserFunctions {
    constructor(walletProvider, address, chainId, isConnected) {
        this.walletProvider = walletProvider;
        this.address = address;
        this.chainId = chainId;
        this.isConnected = isConnected;
        this.initialized = false;
        this.init();
    }
    async waitForInitialization() {
        // Wait until the initialization is complete
        while (!this.initialized) {
            await new Promise(resolve => setTimeout(resolve, 100)); // wait for 100 milliseconds
        }
    }
    async init() {
        try {
            let provider = null;
            let tokenContract;
            let coinPriceGuessingContract;
            let hasProvider;
            let userAddress;
            if (this.isConnected && this.walletProvider !== undefined && this.chainId === chainId_Network) {
                provider = new ethers.providers.Web3Provider(this.walletProvider);
                tokenContract = new ethers.Contract(tokenAddress, TOKEN_ABI, provider.getSigner());
                coinPriceGuessingContract = new ethers.Contract(contractAddress, ContractABI, provider.getSigner());
                hasProvider = true;
                userAddress = this.address;
            }
            else {
                provider = new ethers.providers.JsonRpcProvider(nodeUrl);

                tokenContract = new ethers.Contract(tokenAddress, TOKEN_ABI, provider);
                coinPriceGuessingContract = new ethers.Contract(contractAddress, ContractABI, provider);
                hasProvider = false;
                userAddress = '0x0000000000000000000000000000000000000000';
            }
            this.tokenContract = tokenContract;
            this.coinPriceGuessingContract = coinPriceGuessingContract;
            this.hasProvider = hasProvider;
            this.provider = provider;
            this.initialized = true;
            this.userAddress = userAddress;

        } catch (error) {
            console.error('Error initializing UserFunctions:', error.message || error);
        }
    }
    async getBalance(address) {
        try {
            await this.waitForInitialization(); // Wait for initialization to complete
            const tokenBalance = await this.tokenContract.balanceOf(this.userAddress);
            const tokenSymbol = await this.tokenContract.symbol();
            const amountEth = ethers.utils.formatEther(tokenBalance.toString());
            return {
                amount: amountEth,
                symbol: tokenSymbol
            }
        } catch (error) {
            console.error('Error get balance :', error.message);
        }
    }
    async getCoinList() {
        try {
            await this.waitForInitialization(); // Wait for initialization to complete
            const coinList = await this.coinPriceGuessingContract.getCoinList();
            return coinList;
        } catch (error) {
            console.error('Error getting coin list:', error.message);
        }
    }

    async setOrder(coinSymbol, amount, guess) {
        try {
            await this.waitForInitialization(); // Wait for initialization to complete
            // Check allowance
            const allowance = await this.tokenContract.allowance(this.userAddress, contractAddress);
            const allowanceBigNumber = ethers.BigNumber.from(allowance.toString());
            const amountToSendBigNumber = ethers.BigNumber.from(amount.toString());

            if (allowanceBigNumber.lt(amountToSendBigNumber)) {
                // If allowance is not sufficient, approve the contract to spend tokens
                const balanceWei = ethers.utils.parseEther(amount.toString());
                const approvalTx = await this.tokenContract.approve(contractAddress, balanceWei);
                await approvalTx.wait();
            }

            // Convert the amount from Ether to Wei
            const amountWei = ethers.utils.parseEther(amount.toString());

            // Make the setOrder transaction
            await this.coinPriceGuessingContract.setOrder(coinSymbol, amountWei, guess);
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    async cancelOrder(coinSymbol) {
        try {
            await this.waitForInitialization(); // Wait for initialization to complete
            await this.coinPriceGuessingContract.cancelOrder(coinSymbol);
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    async getOrdersByStatus(status, coinSymbol, listType) {
        // Set default value for coinSymbol if not provided
        const coinSymbolValue = coinSymbol || '';

        try {
            // Wait for initialization to complete
            await this.waitForInitialization();
            let userAddress = this.userAddress;
            if (listType === 'legend' || listType === 'guess') {
                userAddress = '0x0000000000000000000000000000000000000000';
            }
            // Check if user address is default for 'result' and 'order' listTypes
            if ((listType === 'result' || listType === 'order') && userAddress === '0x0000000000000000000000000000000000000000') {
                // Return empty orders if user address is default
                return {
                    orders: [],
                    totalBuy: 0,
                    totalSell: 0
                };
            }
            // Retrieve orders using coinPriceGuessingContract
            const [orders, totalBuy, totalSell] = await this.coinPriceGuessingContract.getOrdersByStatus(status, coinSymbolValue, userAddress);
            // Format and return the result
            return {
                orders: this.convertOrder(orders),
                totalBuy: ethers.utils.formatEther(totalBuy.toString()),
                totalSell: ethers.utils.formatEther(totalSell.toString())
            };
        } catch (error) {
            // Handle and log any errors
            console.error('Error:', error.message);
        }
    }

    convertOrder(orderList) {
        const mappedOrder = [];
        for (let i = 0; i < orderList.length; i++) {
            let order = { ...orderList[i] };  // Create a new object for each order
            order.entryAmount = ethers.utils.formatEther(order.entryAmount.toString());
            order.winningAmount = ethers.utils.formatEther(order.winningAmount.toString());
            order.guess = order.guess.toString();
            order.userShort = this.shortenHexAddress(order.user);
            mappedOrder.push(order);
        }
        return mappedOrder;
    }
    shortenHexAddress(hexAddress) {
        if (hexAddress.length < 10) {
            // Check if the input is too short to be a valid hex address
            return hexAddress;
        }

        const prefix = hexAddress.slice(0, 5); // Take the first 6 characters
        const suffix = hexAddress.slice(-2); // Take the last 4 characters

        return `${prefix}...${suffix}`;
    }
    createNotifFromOrderEvent(order) {
        let notifMessage = '';
        notifMessage += order.isCancelled ? 'Your Order Cancelled: ' : 'Your Order Set: ';

        notifMessage += order.coinSymbol + ' - ' +
            (order.guess.toString() === '0' ? 'UP' : 'DOWN') + ': ' +
            ethers.utils.formatEther(order.amount.toString());

        return notifMessage;
    }
    createNotifFromRevealWinnerEvent(order) {
        let notifMessage = '';
        notifMessage += order.isWinner ? 'Your Won: ' : 'Your Lost: ';

        notifMessage += order.coinSymbol + ' - ' +
            ethers.utils.formatEther(order.amount.toString());

        return notifMessage;
    }

}

export default UserFunctions;
