// AppRouter.js
import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import UserPage from './components/pages/UserPage';

function AppRouter() {
  return (
    <div>

      <Router>

        <Routes>
          <Route path="/" exact element={<UserPage />} />
        </Routes>
      </Router>

    </div>
  );
}

export default AppRouter;