import React, { Component } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../images/upandownlogo.svg';
import ConnectButtonComponent from '../elements/ConnectButton';

export default class NavBar extends Component {

  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: '#1B192C', height: '80px' }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={Logo} alt="Logo" />
            </Typography>
            <ConnectButtonComponent  />
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
