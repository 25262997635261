import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

function CurrentGuess({ coinSymbol, userFunctionsRef, currentStatus,tokenSymbol,walletChanges }) {
  const [hasOrder, setHasOrder] = useState(false);
  const [winAmount, setWinAmount] = useState(0);
  const [winStatus, setWinStatus] = useState(false);

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (userFunctionsRef.current) {
          const listItems = await userFunctionsRef.current.getOrdersByStatus(1, coinSymbol, 'current')
          if (listItems.orders.length >= 1) {
            const item = listItems.orders[0];
    
            setHasOrder(true);
            if (currentStatus === item.guess) {
              setWinAmount(item.winningAmount)
              setWinStatus(true);
            } else {
              setWinAmount(-item.entryAmount)
              setWinStatus(false);
            }
          } else {
            setHasOrder(false);
          }

        }
      } catch (error) {
        console.error('Error fetching legend Items:', error.message);
        // Handle error if needed
      }
    };

    fetchList();
  }, [currentStatus,walletChanges,coinSymbol]);
  if (!hasOrder) {
    return null; // or an empty component, depending on your requirements
  }
  // Define the color based on winStatus
  const amountColor = winStatus ? '#55F2A9' : '#F74E81';

  return (
    <Grid item xs={12} container justifyContent="center">
      <div style={{ color: '#FFFFFF', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
        Current Status:  <strong style={{ color: amountColor }}>{winAmount ?? 0} {tokenSymbol}</strong>
      </div>
    </Grid>
  );
};

export default CurrentGuess;