import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const ButtonDetail = ({ backgroundColor, textColor, iconSrc, buttonText, onClick }) => (
  <Grid item xs={4} style={{
    backgroundColor: backgroundColor,
    height: '56pt',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderTop: 'solid 1px #1B192C',
    borderRight: 'solid 2px #1B192C',
    borderLeft: 'solid 2px #1B192C',
    borderBottom: 'solid 2px #1B192C',
  }}>
    <Button style={{ width: '100%', height: '100%', textTransform: 'none', color: textColor }} onClick={onClick}>
      <img src={iconSrc} style={{ paddingRight: '12px' }} alt="" />
      {buttonText}
    </Button>
  </Grid>
);

export default ButtonDetail;
