import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';


function GuessList({ title, color, side, listType, coinSymbol, orderTrigger, revealTrigger, userFunctionsRef,walletChanges }) {
  // Fetch the coin list when the component mounts
  const [listItems, setListItems] = useState([]);
  const [total, setTotal] = useState(0)
  useEffect(() => {
    const fetchList = async () => {
      try {
        if (coinSymbol) {
          let type = listType === 'next' ? 0 : 1;
          if (userFunctionsRef.current) {
            const listItems = await userFunctionsRef.current.getOrdersByStatus(type, coinSymbol, 'guess')
            const filteredItems = listItems.orders.filter(item => item.guess === side);
            const fixedLengthItems = Array.from({ length: 6 }, (_, index) => filteredItems[index] || { userShort: '..', entryAmount: '' });
            setListItems(fixedLengthItems);

            setTotal(side === '0' ? listItems.totalBuy : listItems.totalSell);
          }
        }
      } catch (error) {
        console.error('Error fetching coin list:', error.message);
        // Handle error if needed
      }
    };

    fetchList();
  }, [side, coinSymbol, listType, orderTrigger, revealTrigger, walletChanges]);



  return (
    <Grid item xs={6} container justifyContent='center' style={{
      background: 'linear-gradient(#1B192C, #222133, #2D2E3F, #333445)',
      padding: '10px',
      border: 'solid 2px #1B192C'
    }}>
      <Grid item xs={5}>
        <div style={{ color: '#FFFFFF', padding: '16px' }}>
          Wallet
        </div>
      </Grid>
      <Grid item xs={5}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          color: '#FFFFFF',
          padding: '9px',
        }}>
          <div style={{
            border: 'solid 2px #8E8D9D',
            borderRadius: '50px', padding: '5px 10px'
          }}>
            Amount
          </div>
        </div>
      </Grid>
      <Grid item container justifyContent='center' style={{
        overflowX: 'auto',
        maxHeight: '200px',
        height: '200px'
      }} xs={12}>
        {listItems.map((item, index) => (
          <Grid item container justifyContent='center' xs={12} key={index}
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            alignItems:'center',
            backgroundColor: index % 2 === 0 ? '#484755' : '',
          }} >
            <Grid item xs={5} style={{ color: color }} >
              {item.userShort}
            </Grid>
            <Grid item style={{
              color: '#FFFFFF',
              display: 'flex',
              justifyContent: 'center'
            }} xs={5} >
              {item.entryAmount}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', paddingTop: '8px', paddingBottom: '8px' }} >
        <div style={{ border: 'solid 2px #8E8D9D', padding: '5px', display: 'inline-block', borderRadius: '8px', color: '#FFFFFF' }}>
          <strong style={{ color: color }}> Total : </strong> <strong> {total} </strong>  </div>
      </Grid>

    </Grid>
  );
}

export default GuessList;
