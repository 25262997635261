import React from 'react';
import Grid from '@mui/material/Grid';

const GuessHeader = () => {
  return (
    <Grid item xs={4} container justifyContent="center">
      <span style={{ fontSize: '20px', fontWeight: '700', color: '#F8CC31' }}>
        What is Your Guess?
      </span>
    </Grid>
  );
};

export default GuessHeader;