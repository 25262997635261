import axios from 'axios';

const binanceApiBaseUrl = 'https://api.binance.com/api/v3';

async function getBinanceTickerPrice(symbol) {
  try {
    const apiUrl = `${binanceApiBaseUrl}/ticker/price?symbol=${symbol}`;

    const response = await axios.get(apiUrl);
    return parseFloat(response.data.price);
  } catch (error) {
    console.error(`Error: ${error.message}`);
    throw error;
  }
}
async function getBinanceKlineData(symbol) {
  try {
    const clientTime = new Date(); // Use client time
    const startTime = calculateLast15MinuteInterval(clientTime);
    
    // Make the API request with dynamic startTime and endTime
    const apiUrl = `${binanceApiBaseUrl}/klines?symbol=${symbol}&interval=1m&startTime=${startTime}&endTime=${clientTime.getTime()}`;

    const response = await axios.get(apiUrl);
    const resultArray = response.data.map(item => ({
      startTime: item[0],
      endTime: item[6],
      startPrice: item[1],
      endPrice: item[4]
    }));

    return resultArray;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    throw error;
  }
}
async function fetchPrice(symbol) {
  try {
    const price = await getBinanceTickerPrice(symbol);
   return price;
  } catch (error) {
    // Handle errors as needed
    console.error(`Error fetching price: ${error.message}`);
  }
}

function calculateNext15MinuteInterval(serverTime) {
  const currentTime = new Date(serverTime);
  const nextInterval = new Date(serverTime);

  nextInterval.setMinutes(Math.ceil(currentTime.getMinutes() / 15) * 15, 0, 0);

  return nextInterval - currentTime;
}
function calculateLast15MinuteInterval(serverTime) {
  const currentTime = new Date(serverTime);
  const lastInterval = new Date(serverTime);

  lastInterval.setMinutes(Math.floor(currentTime.getMinutes() / 15) * 15, 0, 0);

  return lastInterval.getTime();
}
async function startCountdown(setRemainingTime) {
  let countdownInterval;

  try {
    const clientTime = new Date(); // Use client time
    const countdownDuration = calculateNext15MinuteInterval(clientTime);
    const updateInterval = 1000; // Update every second
    let remainingTime = countdownDuration;

    const updateCountdown = () => {
      remainingTime = Math.max(0, remainingTime - updateInterval); // Ensure remainingTime doesn't go below zero
      setRemainingTime(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
      }
    };

    updateCountdown(); // Initial update

    countdownInterval = setInterval(updateCountdown, updateInterval);
  } catch (error) {
    console.error('Error starting countdown:', error.message);
    // Handle errors
  }
}


export { fetchPrice, startCountdown,getBinanceKlineData,calculateNext15MinuteInterval };
