import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ResponsiveContainer } from 'recharts';
import { getBinanceKlineData, fetchPrice } from '../../utils/PriceUtils';
import CoinChart from './CoinChart';
import CurrentGuess from './CurrentGuess';

const GraphView = ({ selectedCoin, coinList, userFunctionsRef,tokenSymbol,walletChanges }) => {
  const intervalDurationForFetchingPrice = 5000;
  const [currentPrice, setCurrentPrice] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [currentStatus, setcurrentStatus] = useState('');
  const [refreshGraph,setRefreshGraph] = useState(false);

  useEffect(() => {
    // Check if coinSymbol is not an empty string
    if (selectedCoin !== '') {
      const selected = coinList.find((coin) => coin.symbol === selectedCoin);
      if (selected && selected.identifier) {
        const coinIdentifier = selected.identifier;
        const fetchCurrentPrice = async () => {
          try {
            const price = await fetchPrice(coinIdentifier);
            setCurrentPrice(price);
          } catch (error) {
            console.error('Error fetching price:', error);
          }
        };
        fetchCurrentPrice();

        // Set up an interval to fetch the price every 5 seconds
        const intervalId = setInterval(fetchCurrentPrice, intervalDurationForFetchingPrice);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }
    }
  }, [selectedCoin, coinList]);

  function calculateCurrentStatus(data) {
    // Check if there is at least one item in the result array
    if (data.length > 1) {
      // Get the endPrice of the last item
      const lastItemEndPrice = data[data.length - 1].endPrice;

      // Get the endPrice of the first item
      const firstItemEndPrice = data[0].endPrice;

      // Calculate the price difference
      const priceChange = lastItemEndPrice - firstItemEndPrice;

      // Determine if the price change is Up or Down
      const direction = priceChange > 0 ? '0' : '1';
      console.log('direction',direction)
      // Return the result
      return direction;
    } else {
      // If there is not enough data to calculate the price change, return a message
      return '';
    }
  }
  useEffect(() => {
    const timer = setInterval(() => {
        const totalSeconds = Math.floor(new Date().getTime() / 1000); // Get current time in seconds without milliseconds
        let seconds = totalSeconds % 60;
        if(seconds === 5) setRefreshGraph((prevFlag) => !prevFlag);
    }, 1000);

    return () => clearInterval(timer); 
}, []);
  useEffect(() => {
    const fetchKlineData = async () => {
      try {
        const selected = coinList.find((coin) => coin.symbol === selectedCoin);
        const coinIdentifier = selected.identifier;
        const klineData = await getBinanceKlineData(coinIdentifier);
        const graphData = klineData.map(item => item.endPrice.toString());
        setcurrentStatus(calculateCurrentStatus(klineData));

        setGraphData(graphData)
      } catch (error) {
        console.error('Error fetching price:', error);
      }
    };
    fetchKlineData();

  }, [selectedCoin, coinList, refreshGraph]);
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} container justifyContent="center">
        <div style={{ color: '#FFFFFF', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
          Current Price: ${currentPrice}
        </div>
      </Grid>
      <CurrentGuess userFunctionsRef={userFunctionsRef} coinSymbol={selectedCoin} currentStatus={currentStatus}
       tokenSymbol={tokenSymbol} walletChanges={walletChanges}/>
      <Grid item xs={12} container justifyContent="center">
        <ResponsiveContainer width="90%" >
          <CoinChart graphData={graphData} />
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default GraphView;
