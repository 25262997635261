import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';

const CoinChart = ({ graphData }) => {
  const chartRef = useRef(null);

  const chartColors = {
    red: 'rgb(247, 78, 129)',
    yellow: 'rgb(248, 204, 49)',
    green: 'rgb(85, 242, 169)',
  };

  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const { ctx, chartArea: { left, top, right, bottom }, scales: { x, y } } = chart;
      const midX = x.getPixelForValue(0);
      const midY = y.getPixelForValue(graphData[0]);
      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.restore();
    },
  };

  const labels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Dataset 2',
        data: graphData,
        borderColor: chartColors.yellow,
        backgroundColor: chartColors.yellow,
      },
    ],
  };

  useEffect(() => {
    if (!graphData) {
      // Handle the case where graphData is not available
      return;
    }

    // Create a new chart instance
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        plugins: {
          quadrants: {
            topRight: chartColors.green,
            bottomRight: chartColors.red,
          },
          legend: {
            display: false,
          },
        },
      },
      plugins: [quadrants],
    });

    // Clean up the chart when the component unmounts
    return () => {
      myChart.destroy();
    };
  }, [graphData]);

  return (
    <div>
      <canvas  ref={chartRef}></canvas>
    </div>
  );
};

CoinChart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.number),
};

export default CoinChart;
