import React, { useState, useEffect, useRef } from 'react';
import CoinListSelect from '../elements/CoinListSelect';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GuessList from '../elements/GuessList';
import ButtonDetail from '../elements/ButtonDetail'; // Adjust the path based on your project structure

import LegendIcon from '../images/legend.svg';
import LegendSelectedIcon from '../images/legend_selected.svg';
import ResultIcon from '../images/result.svg'; // Adjust the path to your AwardFill component
import ResultSelectedIcon from '../images/result_selected.svg'; // Adjust the path to your Wallet component
import OrderIcon from '../images/order.svg'; // Adjust the path to your AwardFill component
import OrderSelectedIcon from '../images/order_selected.svg'; // Adjust the path to your Wallet component

import LegendTable from '../elements/LegendTable';
import ResultTable from '../elements/ResultTable';
import OpenOrderTable from '../elements/OpenOrderTable';
import GraphButton from '../elements/GraphButton';

import Order from '../elements/Order';
import GuessHeader from '../elements/GuessHeader';
import GraphHeader from '../elements/GraphHeader';
import GraphView from '../elements/GraphView';
import { calculateNext15MinuteInterval } from '../../utils/PriceUtils';
import UserFunctions from '../../utils/userFunctions';
import NavBar from '../view/NavBar';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';

function UserPage() {
  const { walletProvider } = useWeb3ModalProvider();
  const userFunctionsRef = useRef(null);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [walletChanges, setWalletChanges] = useState(false);


  // Initialize UserFunctions on component mount
  useEffect(() => {
    userFunctionsRef.current = new UserFunctions(walletProvider, address, chainId, isConnected);
    setWalletChanges((prevFlag) => !prevFlag);

  }, [walletProvider, address, chainId, isConnected]);

  //const [activeList, setActiveList] = useState(currentWallet);
  const [selectedListButton, setSelectedListButton] = useState('current'); // initial selected state
  const [selectedDetailButton, setSelectedDetailButton] = useState('result');
  const [showGraphView, setShowGraphView] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(''); // Set the first item as the initial selected coin

  const [avaliableAmount, setAvaliableAmount] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [orderTrigger, setOrderTrigger] = useState(false);
  const [revealTrigger, setRevealTrigger] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  

  const handleDetailClick = (buttonType) => {
    setSelectedDetailButton(buttonType);
  };
  const handleListClick = (tableData) => {
    setSelectedListButton(tableData);
  };
  const handleGraphButtonClick = () => {
    setShowGraphView(!showGraphView);
  };
  const handleSetOrderButtonClick = async (amount, side) => {
    await userFunctionsRef.current.setOrder(selectedCoin, amount, side)
  };

  
  useEffect(() => {
    const fetchCoinList = async () => {
      try {
        const coinsData = await userFunctionsRef.current.getCoinList();
        setCoinList(coinsData);
        if (coinsData.length > 0) {
          setSelectedCoin(coinsData[0].symbol);
        }
      } catch (error) {
        console.error('Error fetching coin list:', error.message);
        // Handle error if needed
      }
    };

    fetchCoinList();
  }, [userFunctionsRef.current]);

 // Second useEffect
useEffect(() => {
  const updateBalance = async () => {
    try {
      const balance = await userFunctionsRef.current.getBalance();
      if (address) { // Check if address is defined
        console.log('Balance addres: ', address);
        setAvaliableAmount(balance.amount);
        setTokenSymbol(balance.symbol);
      }else{
        setAvaliableAmount(0);
        setTokenSymbol(balance.symbol);
      }
    } catch (error) {
      console.error('Error fetching balance:', error.message);
      // Handle error if needed
    }
  };

  updateBalance();
}, [address]);

  useEffect(() => {
    let orderEventSubscription, tokensDistributedSubscription;
    if (userFunctionsRef.current.hasProvider) { 
      // Subscribe to 'OrderEvent' and update state when the event is triggered
      orderEventSubscription = userFunctionsRef.current.coinPriceGuessingContract.on('OrderEvent', (user, coinSymbol, amount, guess, isCancelled, event) => {
        const newOrderEvent = { user, coinSymbol, amount, guess, isCancelled };

        // Set the notification message
        if (newOrderEvent.user.toLowerCase() === userFunctionsRef.current.userAddress.toLowerCase()) {
          setNotificationMessage(userFunctionsRef.current.createNotifFromOrderEvent(newOrderEvent));
          // Display inline notification
          setShowNotification(true);
          // Optionally, hide the notification after a delay
          setTimeout(() => {
            setShowNotification(false);
            setOrderTrigger((prevFlag) => !prevFlag);
          }, 5000); // Adjust the timeout as needed
        }
      });

      // Subscribe to 'TokensDistributed' and update state when the event is triggered
      tokensDistributedSubscription = userFunctionsRef.current.coinPriceGuessingContract.on('TokensDistributed', (user, amount, isWinner, coinSymbol, event) => {
        const newTokensDistributedEvent = { user, amount, isWinner, coinSymbol };

        // Set the notification message
        if (newTokensDistributedEvent.user.toLowerCase() === userFunctionsRef.current.userAddress.toLowerCase()) {
          setNotificationMessage(userFunctionsRef.current.createNotifFromRevealWinnerEvent(newTokensDistributedEvent));
          // Display inline notification
          setShowNotification(true);
          // Optionally, hide the notification after a delay
          setTimeout(() => {
            setShowNotification(false);
            setRevealTrigger((prevFlag) => !prevFlag);
          }, 5000); // Adjust the timeout as needed
        }
      });
    }

    // Cleanup subscriptions on component unmount or when hasProvider becomes falsy
    return () => {
      if (orderEventSubscription) orderEventSubscription.removeAllListeners();
      if (tokensDistributedSubscription) tokensDistributedSubscription.removeAllListeners();
    };
  }, [userFunctionsRef.current && userFunctionsRef.current.hasProvider]);



  return (
    <div>
      <NavBar />
      {/* Inline Notification */}
      {showNotification && (
        <Grid item container justifyContent="center" style={{ background: 'linear-gradient(to bottom, #333445, #1B192C)' }}
          alignItems="center" spacing={2}>
          <div style={{ background: 'lightgreen', padding: '10px', margin: '10px', borderRadius: '5px' }}>
            Notif Message
            {notificationMessage}
          </div>
        </Grid>
      )}

      <Grid item container justifyContent="center" style={{ background: 'linear-gradient(to bottom, #333445, #1B192C)' }}
        alignItems="center" spacing={2}>
        {coinList.length > 0 && (
          <CoinListSelect
            coinList={coinList}
            selectedCoin={selectedCoin}
            onChange={(e) => setSelectedCoin(e.target.value)}
          />
        )}
        {showGraphView ? <GraphHeader /> : <GuessHeader />}
        <GraphButton isClicked={showGraphView} onClick={() => handleGraphButtonClick()} />
        {showGraphView ?
          <GraphView selectedCoin={selectedCoin} coinList={coinList} tokenSymbol={tokenSymbol}
            userFunctionsRef={userFunctionsRef} walletChanges={walletChanges} /> :
          <Order
            avaliableAmount={avaliableAmount}
            tokenSymbol={tokenSymbol}
            setOrder={handleSetOrderButtonClick} />}

      </Grid>
      <Grid item container
        alignItems="center" xs={12} >
        <Grid item xs={6}  >
          <Button style={{
            width: '100%',
            height: '58px',
            color: selectedListButton === 'current' ? '#000000' : '#8E8D9D',
            textTransform: 'none',
            background: selectedListButton === 'current' ? 'linear-gradient(182deg, #DAA520,#EBAC7D, #E7A296, #DC86D8)' : '#363444',
          }
          }
            onClick={() => handleListClick('current')}>
            Current
          </Button>
        </Grid>
        <Grid item xs={6} >
          <Button style={{
            width: '100%',
            height: '58px',
            color: selectedListButton === 'next' ? '#000000' : '#8E8D9D',
            textTransform: 'none',
            background: selectedListButton === 'next' ? 'linear-gradient(182deg, #DAA520,#EBAC7D, #E7A296, #DC86D8)' : '#363444',
          }}
            onClick={() => handleListClick('next')}>
            Next Guess
          </Button>
        </Grid>

        <GuessList title="UP" listType={selectedListButton} coinSymbol={selectedCoin} walletChanges={walletChanges}
          side="0" color='#55F2A9' orderTrigger={orderTrigger} revealTrigger={revealTrigger} userFunctionsRef={userFunctionsRef} />
        <GuessList title="DOWN" listType={selectedListButton} coinSymbol={selectedCoin} walletChanges={walletChanges}
          side="1" color='#F74E81' orderTrigger={orderTrigger} revealTrigger={revealTrigger} userFunctionsRef={userFunctionsRef} />
        {/* <CurrentGuess data={currentData}></CurrentGuess> */}
        <ButtonDetail
          backgroundColor="#363545"
          textColor={selectedDetailButton === 'result' ? '#F8CC31' : '#8E8D9D'}
          iconSrc={selectedDetailButton === 'result' ? ResultSelectedIcon : ResultIcon}
          buttonText="Results"
          onClick={() => handleDetailClick('result')}
        />
        <ButtonDetail
          backgroundColor="#363545"
          textColor={selectedDetailButton === 'order' ? '#F8CC31' : '#8E8D9D'}
          iconSrc={selectedDetailButton === 'order' ? OrderSelectedIcon : OrderIcon}
          buttonText="Open Orders"
          onClick={() => handleDetailClick('order')}
        />
        <ButtonDetail
          backgroundColor="#363545"
          textColor={selectedDetailButton === 'legend' ? '#F8CC31' : '#8E8D9D'}
          iconSrc={selectedDetailButton === 'legend' ? LegendSelectedIcon : LegendIcon}
          buttonText="Legends"
          onClick={() => handleDetailClick('legend')}
        />



        {selectedDetailButton === 'result' && <ResultTable
          revealTrigger={revealTrigger} userFunctionsRef={userFunctionsRef} walletChanges={walletChanges} />}
        {selectedDetailButton === 'order' && <OpenOrderTable orderTrigger={orderTrigger}
          revealTrigger={revealTrigger} userFunctionsRef={userFunctionsRef} walletChanges={walletChanges} />}
        {selectedDetailButton === 'legend' && <LegendTable revealTrigger={revealTrigger}
          userFunctionsRef={userFunctionsRef} walletChanges={walletChanges} />}
      </Grid>
    </div>
  );
}

export default UserPage;