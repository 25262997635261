import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',           // Metin rengi
                    backgroundColor: '#1B192C',  // Arka plan rengi
                },
                icon: {
                    color: '#FFFFFF', // Açılır okun rengi
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
              paper: {
                backgroundColor: '#1B192C',  // Menü arka plan rengi
                color: '#FFFFFF'
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 16, // İstediğiniz border radius değerini ayarlayabilirsiniz
              },
            },
          },
    }
});
function CoinListSelect({ coinList, selectedCoin, onChange }) {
    return (
            <ThemeProvider theme={theme}>
                <Select
                    style={{ border: 'solid 2px #F8CC31', height:'54px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCoin}
                    onChange={onChange}
                >
                    {coinList.map((coin) => (
                        <MenuItem key={coin.symbol} value={coin.symbol}>
                            {coin.symbol}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
    );
}

export default CoinListSelect;