import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import UserFunctions from '../../utils/userFunctions';


function ResultTable({ revealTrigger,userFunctionsRef,walletChanges}) {
    const [listItems,setListItems] = useState([]);
    useEffect(() => {
        const fetchList = async () => {
          try {
              if(userFunctionsRef.current){
              const listItems = await userFunctionsRef.current.getOrdersByStatus(2,null,'result')
              const fixedLengthItems = Array.from({ length: 6 }, (_, index) => listItems.orders[index] || 
              { 
                userShort: '..',
                entryAmount: '0',
                winningAmount: '0',
                guess: 'DOWN',
                coinSymbol: 'BTC'
             });
              setListItems(fixedLengthItems);
            }
          } catch (error) {
            console.error('Error fetching legend Items:', error.message);
            // Handle error if needed
          }
        };
      
        fetchList();
      }, [revealTrigger,walletChanges]);

    return (
        <Grid item container style={{
            background: '#1B192C',
            padding: '10px',
            border: 'solid 2px #1B192C',
            overflowX: 'auto', 
            maxHeight: '300px',
            height: '300px'
        }}>
            {['Guess', 'Entry','Win'].map((label, index) => (
                <Grid item key={index} xs={4}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                        padding: '9px',
                    }}>
                        <div style={{
                            border: 'solid 2px #8E8D9D',
                            borderRadius: '50px',
                            padding: '5px 10px',
                        }}>
                            {label}
                        </div>
                    </div>
                </Grid>
            ))}
    
            {/* Body */}
            {listItems.map((item, index) => (
                <Grid item container key={index} style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <Grid item xs={4} >
                        <div style={{
                            backgroundColor: index % 2 === 0 ? '#2B2A3D' : '',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#FFFFFF',
                            margin: '0px 2px',
                            padding: '5px'
                        }}>
                            <div style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: item.guess === '0' ? '#55F2A9' : '#F74E81',
                                borderRadius: '50%',
                            }}>

                            </div>
                            <span style={{ paddingLeft: '12px' }}>
                                {item.coinSymbol}
                            </span>

                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{
                            backgroundColor: index % 2 === 0 ? '#2B2A3D' : '',
                            display: 'flex',
                            justifyContent: 'center',
                            color: item.guess === '0' ? '#55F2A9' : '#F74E81',
                            margin: '0px 2px',
                            padding:'5px'
                        }}>
                            {item.entryAmount}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{
                            backgroundColor: index % 2 === 0 ? '#2B2A3D' : '',
                            display: 'flex',
                            justifyContent: 'center',
                            color: item.guess === '0' ? '#55F2A9' : '#F74E81',
                            margin: '0px 2px',
                            padding:'5px'
                        }}>
                            {item.isWinner ? item.winningAmount: 0}
                        </div>
                    </Grid>
    
                </Grid>
            ))}
        </Grid>
    );
}

export default ResultTable;
